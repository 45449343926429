<!-- 续费管理 -->
<template>
    <div>
        <div style="display: flex;justify-content: space-between;padding: 24px;">
            <div style="display: flex;align-items: center;">
                <div style="font-size: 26px; font-weight: bold">续费管理</div>
            </div>

            <div>
                <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
                    @click='add'>+新增选项</el-button>
            </div>

        </div>
        <div class="main">
            <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
                style="width: 100%; min-height: 600px">

                <el-table-column prop="price" label="价格/元" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.price / 100 }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="weight" label="权重" align="center">
                </el-table-column>
                <el-table-column prop="timeMonthExtern" label="年数" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.timeMonthExtern / 12 }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="状态" align="center">
                    <template slot-scope="scope">
                        <div>
                            <!-- {{ scope.row.isValidity }} -->
                            <el-switch v-model="scope.row.checked" active-color="#13ce66"
                                @change="changeRenewPay($event, scope.row)"></el-switch>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" align="center">
                    <template slot-scope="scope">
                        <div style="display: flex;align-items: center;justify-content: center;">
                            <div style="color: #409EFF;" @click="deleRenewPay(scope.row.id)">删除</div>
                            <div style="color: #409EFF;margin-left: 20px;" @click="editRenewPay(scope.row)">编辑</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div style="display: flex;align-items: center;justify-content: flex-end;">

            </div>
        </div>

        <el-dialog title="新增选项" :visible.sync="dialogVisible" width="30%">
            <div style="display: flex;flex-direction: column;color: #7E8887;">
                <div class="grid-content bg-purple" style="display: flex;align-items:center;">
                    <div style="flex-shrink: 0;color: #7E8887;" class="ft">价格：</div>
                    <el-input v-model="price" placeholder="请输入价格" style="width: 60%;"></el-input>
                </div>
                <div class="grid-content bg-purple" style="display: flex;align-items: center;margin: 20px 0;">
                    <div style="flex-shrink: 0;color: #7E8887;" class="ft">年数：</div>
                    <el-input v-model="timeMonthExtern" placeholder="请输入年数" style="width:60%"
                        oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
                </div>
                <div class="grid-content bg-purple" style="display: flex;align-items: center;">
                    <div style="flex-shrink: 0;color: #7E8887;" class="ft">权重：</div>
                    <el-input v-model="weight" placeholder="权重数字越大，越靠前" style="width:60%"
                        oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
                </div>
                <div class="grid-content bg-purple" style="display: flex;align-items: center;margin-top: 20px;">
                    <div style="flex-shrink: 0;color: #7E8887;" class="ft">是否开启：</div>
                    <el-switch v-model="isValidity" active-color="#13ce66">
                    </el-switch>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmRenew" style="background: #00A78E;">新增选项</el-button>
            </span>
        </el-dialog>

    </div>
</template>
  
<script>

import { renewPaylist, stateRenewPay, deleteRenewPay } from "../../apis/index";
export default {
    data() {
        return {
            id: '',
            goodsType: 1,
            price: '',
            timeMonthExtern: '',
            weight: '',
            dialogVisible: false,
            isValidity: '',
            tableData: [

            ],
        };
    },
    watch: {

    },
    created() {
        this.getList()
    },


    methods: {
        add() {
            this.dialogVisible = true
            this.id = ''
            this.price = ''
            this.weight = ''
            this.timeMonthExtern = ''
            this.isValidity = ''
        },
        changeRenewPay(e, row) {
            const params = {
                id: row.id,
                goodsType: row.goodsType,
                isValidity: Number(e),
                price: row.price,
                timeMonthExtern: row.timeMonthExtern,
                weight: row.weight
            }
            stateRenewPay(params, 'put').then(res => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功')
                    this.getList()
                    this.dialogVisible = false
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(err => {
                this.$message.error('请求出错~')
            })
        },
        editRenewPay(row) {
            console.log(row)
            this.dialogVisible = true
            this.id = row.id
            this.price = row.price / 100
            this.weight = row.weight
            this.timeMonthExtern = row.timeMonthExtern / 12
            this.isValidity = Boolean(row.isValidity)
        },
        async getList() {
            try {
                const { data } = await renewPaylist()
                if (data.code == 200) {
                    data.data.forEach(item => {
                        item.checked = Boolean(item.isValidity)
                    })
                    this.tableData = data.data
                } else {
                    this.$message.success(data.msg)
                }
                console.log(data.data)
            } catch {
                this.$message.error('请求出错~')
            }
        },
        deleRenewPay(id) {//删除
            let that = this
            this.$confirm('确认删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteRenewPay({ id }).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.getList()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(err => {
                    this.$message.error('请求出错~')
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        confirmRenew() {//保存/编辑
            const params = {
                id: this.id,
                goodsType: this.goodsType,
                isValidity: Number(this.isValidity),
                price: this.price * 100,
                timeMonthExtern: this.timeMonthExtern * 12,
                weight: this.weight
            }
            if (this.id) {
                stateRenewPay(params, 'put').then(res => {
                    if (res.data.code == 200) {
                        this.getList()
                        this.dialogVisible = false
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).catch(err => {
                    this.$message.error('请求出错~')
                })
            } else {
                stateRenewPay(params, 'post').then(res => {
                    if (res.data.code == 200) {
                        this.getList()
                        this.dialogVisible = false
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).catch(err => {
                    this.$message.error('请求出错~')
                })
            }

        },
    }
};
</script>
  
<style lang='less' scoped>
.main {
    // min-height: 800px;
    background: #fff;
    // margin: 19px 0;
    padding: 23px;
    border-radius: 14px;
}

// .main > div:first-child {
//   font-size: 26px;
//   // font-weight: bold;
// }
// .main > div:nth-child(2) {
//   display: flex;
//   // justify-content: space-between;
//   flex-direction: column;
//   height: 34px;
//   margin: 18px 0;
//   div {
//     display: flex;
//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }
/deep/.el-radio-button__inner {
    height: 34px;
    line-height: 10px;
}

.cinput /deep/.el-input__inner {
    height: 34px;
    line-height: 10px;
}

/deep/.el-button--primary {
    height: 34px;
    line-height: 10px;
}

/deep/.el-table::before {
    height: 0;
}

.ac /deep/.el-input__inner {
    border: none;
    background: #f7f7f7;
    // margin-right: 11px;
}

.red {
    color: #ff1b0b;
}
</style>
  